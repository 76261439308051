import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  private accordionViews: Array<string> = [
    '/preise',
    '/faq'
  ]

  constructor(
    @Inject(DOCUMENT) private _doc: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private location: Location
  ) { }

  private getDocument(): Document {
    return this._doc;
  }

  toElement(element: string): void {
    let id: string = element;
    let behavior: ScrollBehavior = 'auto';
    const hash: string = this.location.path(true).replace(`${this.location.path()}`, '').replace('#', '');

    if (hash && !this.accordionViews.includes(this.location.path())) {
      id = hash
      behavior = 'smooth';
    }

    const node: HTMLElement | null = this.getDocument().getElementById(id);

    if (isPlatformBrowser(this.platformId)) {
      node!.scrollIntoView({
        behavior: behavior,
        block: 'start',
        inline: 'nearest'
      });
    }
  }
}
