import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDrawer } from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root'
})
export class DrawerService {
  private drawer!: MatDrawer;

  constructor(@Inject(DOCUMENT) private _doc: Document) { }

  private getDocument(): Document {
    return this._doc;
  }

  setDrawer(drawer: MatDrawer): void {
    this.drawer = drawer;
  }

  close(): void {
    this.drawer.close();

    const element: HTMLElement | null = this.getDocument().getElementById('container');
    if (element) {
      element.scrollIntoView({ block: 'start' });
    }
  }

  toggle(): void {
    this.drawer.toggle();
  }
}
