<div *ngIf="snowflake">
  <div *ngFor='let n of snowflakes' class="snowflake"></div>
</div>
<mat-drawer-container hasBackdrop="true">
  <mat-drawer #drawer mode="over">
    <nav>
      <app-sidenav class="contents"></app-sidenav>
    </nav>
  </mat-drawer>

  <mat-drawer-content>
    <header>
      <app-toolbar class="contents"></app-toolbar>
    </header>
    
    <div id="top" style="position: absolute; top: -100px; left: 0;"></div>
    <div class="content" role="main">
      <nav>
        <app-navigator class="contents"></app-navigator>
      </nav>
      <main id="content">
        <router-outlet></router-outlet>
      </main>
      <footer>
        <app-footer class="contents"></app-footer>
      </footer>
      
      <div class="fab">
        <div *ngIf="isWinter()" class="snowflake-button">
          <button mat-fab color="secondary" aria-label="Schnee" (click)="toggleSnow()">
            <mat-icon>ac_unit</mat-icon>
          </button>
        </div>
        <div class="booking" *ngIf="!isBooking()">
          <button mat-fab color="secondary" aria-label="Online buchen" routerLink="/onlinebuchung" (click)="drawerService.close()">
            <mat-icon>calendar_month</mat-icon>
          </button>
        </div>
    </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
