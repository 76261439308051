import { Component, OnInit } from '@angular/core';

import { faInstagram, faWhatsapp, faGoogle } from '@fortawesome/free-brands-svg-icons';

import { DrawerService } from '../../services/drawer.service';
import { TrackingService } from '../../services/tracking.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public faInstagram = faInstagram;
  public faWhatsapp = faWhatsapp;
  public faGoogle = faGoogle;

  constructor(
    public drawerService: DrawerService,
    public trackingService: TrackingService
  ) { }

  ngOnInit(): void {
  }

}
