import { Component, OnInit } from '@angular/core';

import { DrawerService } from '../../services/drawer.service';
import { LinkService } from '../../services/link.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  constructor(
    public drawerService: DrawerService,
    public linkService: LinkService
  ) { }

  ngOnInit(): void {
  }
}
