<mat-toolbar>
  <button mat-button aria-label="Datenschutz" routerLink="/datenschutz" (click)="drawerService.close()">Datenschutz</button>
  <button mat-button aria-label="Impressum" routerLink="/impressum" (click)="drawerService.close()">Impressum</button>

  <span class="toolbar-spacer"></span>
  <div class="social">
    <a (click)="trackingService.trackEvent('Link', 'Instagram')" href="https://www.instagram.com/permanent.verschoenert" aria-label="Instagram" title="Instagram" target="_blank"><fa-icon [icon]="faInstagram"></fa-icon></a>
    <a (click)="trackingService.trackEvent('Link', 'WhatsApp')" href="https://wa.me/4915128358406" aria-label="Whatsapp" title="Whatsapp" target="_blank"><fa-icon [icon]="faWhatsapp"></fa-icon></a>
    <a (click)="trackingService.trackEvent('Link', 'Maps')" class="google" href="https://goo.gl/maps/tgVK933tRaWhcsUg8" aria-label="Google Maps" title="Google Maps" target="_blank"><fa-icon [icon]="faGoogle"></fa-icon></a>
  </div>
</mat-toolbar>
