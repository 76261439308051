import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  private lastTrackedCategory: string = '';
  private lastTrackedName: string = '';

  constructor() { }

  trackRouteChange(): void {
    (<any>window)._paq.push(['setCustomUrl', document.URL]);
    (<any>window)._paq.push(['setDocumentTitle', document.title]);
    (<any>window)._paq.push(['trackPageView']);
  }

  trackEvent(category: string, name: string): void {
    if (category !== this.lastTrackedCategory || name !== this.lastTrackedName) {
      (<any>window)._paq.push(['trackEvent', category, name]);
      this.lastTrackedCategory = category;
      this.lastTrackedName = name;
    }
  }
}
