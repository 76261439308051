import { Component, OnInit } from '@angular/core';

import { DrawerService } from '../../services/drawer.service';
import { LinkService } from '../../services/link.service';
import { TrackingService } from '../../services/tracking.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  constructor(
    public drawerService: DrawerService,
    public linkService: LinkService,
    public trackingService: TrackingService
  ) { }

  ngOnInit(): void {
  }
}
