import { Component, OnInit } from '@angular/core';

import { DrawerService } from '../../services/drawer.service';
import { LinkService } from '../../services/link.service';
import { TrackingService } from '../../services/tracking.service';

@Component({
  selector: 'app-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.scss']
})
export class NavigatorComponent implements OnInit {
  constructor(
    public drawerService: DrawerService,
    private linkService: LinkService,
    public trackingService: TrackingService
  ) { }

  ngOnInit(): void {
  }

  isMenuActive(baseRoute: string): string {
    if (this.linkService.baseRoute(baseRoute)) {
      return 'active'
    } else {
      return '';
    }
  }
}
