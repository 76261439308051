import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/index/index.module').then(m => m.IndexModule),
    data: {
      'title': 'Beautylounge permanent verschönert in Nürnberg',
      'keywords': 'permanent verschönert, Kosmetikstudio, Beautylounge, Augenbrauenlifting, Wimpernlifting, Kosmetik, Aqua Facial, WowBrows, Waxing, Nürnberg',
      'description': 'Herzlich willkommen bei permanent verschönert - Deine Beautylounge rund um Wimpern, Augenbrauen, Kosmetik, Waxing und Aqua Facial in Nürnberg.',
      'canonical': 'https://www.permanent-verschoenert.de',
      'og:type': 'website',
      'og:title': 'Beautylounge permanent verschönert in Nürnberg',
      'og:description': 'Herzlich willkommen bei permanent verschönert - Deine Beautylounge rund um Wimpern, Augenbrauen, Kosmetik, Waxing und Aqua Facial in Nürnberg.',
      'og:image': 'https://www.permanent-verschoenert.de/assets/images/meta_image.jpg',
      'og:url': 'https://www.permanent-verschoenert.de'
    }
  },
  {
    path: 'ueber-mich',
    loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule),
    data: {
      'title': 'Michaela Büchler von deiner Beautylounge permanent verschönert',
      'keywords': 'Nürnberg, Michaela Büchler, Kosmetik, Studio, Kosmetikstudio',
      'description': 'Ich habe meine Leidenschaft zum Beruf gemacht. Mein Studio befindet sich in Nürnberg - ich freue mich, Dich verschönern & verwöhnen zu dürfen!',
      'canonical': 'https://www.permanent-verschoenert.de/ueber-mich',
      'og:type': 'website',
      'og:title': 'Michaela Büchler von deiner Beautylounge permanent verschönert',
      'og:description': 'Ich habe meine Leidenschaft zum Beruf gemacht. Mein Studio befindet sich in Nürnberg - ich freue mich, Dich verschönern & verwöhnen zu dürfen!',
      'og:image': 'https://www.permanent-verschoenert.de/assets/images/meta_image.jpg',
      'og:url': 'https://www.permanent-verschoenert.de/ueber-mich'
    }
  },
  {
    path: 'behandlungen/wimpernlifting',
    loadChildren: () => import('./pages/lash-lifting/lash-lifting.module').then(m => m.LashLiftingModule),
    data: {
      'title': 'Wimpernlifting bei permanent verschönert in Nürnberg',
      'keywords': 'Wimpernlifting, Lashlifting, Wimpernlaminierung, Nürnberg',
      'description': 'Lass Deine Augen schon morgens nach dem Aufstehen strahlen - ich biete Dir ein professionelles Wimpernlifting in meinem Studio in Nürnberg.',
      'canonical': 'https://www.permanent-verschoenert.de/behandlungen/wimpernlifting',
      'og:type': 'website',
      'og:title': 'Wimpernlifting bei permanent verschönert in Nürnberg',
      'og:description': 'Lass Deine Augen schon morgens nach dem Aufstehen strahlen - ich biete Dir ein professionelles Wimpernlifting in meinem Studio in Nürnberg.',
      'og:image': 'https://www.permanent-verschoenert.de/assets/images/meta_image.jpg',
      'og:url': 'https://www.permanent-verschoenert.de/behandlungen/wimpernlifting'
    }
  },
  {
    path: 'behandlungen/augenbrauenlifting',
    loadChildren: () => import('./pages/brow-lifting/brow-lifting.module').then(m => m.BrowLiftingModule),
    data: {
      'title': 'Augenbrauenlifting bei permanent verschönert in Nürnberg',
      'keywords': 'Augenbrauenlifting, Browlifting, Augenbrauenstyling, zupfen, Faden, Nürnberg',
      'description': 'In meinem Studio in Nürnberg biete ich Dir ein professionelles Augenbrauenlifting und Augenbrauenstyling.',
      'canonical': 'https://www.permanent-verschoenert.de/behandlungen/augenbrauenlifting',
      'og:type': 'website',
      'og:title': 'Augenbrauenlifting bei permanent verschönert in Nürnberg',
      'og:description': 'In meinem Studio in Nürnberg biete ich Dir ein professionelles Augenbrauenlifting und Augenbrauenstyling.',
      'og:image': 'https://www.permanent-verschoenert.de/assets/images/meta_image.jpg',
      'og:url': 'https://www.permanent-verschoenert.de/behandlungen/augenbrauenlifting'
    }
  },
  {
    path: 'behandlungen/wow-brows',
    loadChildren: () => import('./pages/wow-brow/wow-brow.module').then(m => m.WowBrowModule),
    data: {
      'title': 'WowBrows & Augenbrauen Styling bei permanent verschönert in Nürnberg',
      'keywords': 'Wowbrows, Augenbrauen, färben, zupfen, fadentechnik, threading, Nürnberg',
      'description': 'In meinem Studio in Nürnberg biete ich Dir die beliebten WowBrows - das perfekte rundum Styling für Deine Augenbrauen an.',
      'canonical': 'https://www.permanent-verschoenert.de/behandlungen/wow-brows',
      'og:type': 'website',
      'og:title': 'WowBrows bei permanent verschönert in Nürnberg',
      'og:description': 'In meinem Studio in Nürnberg biete ich Dir die beliebten WowBrows - das perfekte rundum Styling für Deine Augenbrauen an.',
      'og:image': 'https://www.permanent-verschoenert.de/assets/images/meta_image.jpg',
      'og:url': 'https://www.permanent-verschoenert.de/behandlungen/wow-brows'
    }
  },
  {
    path: 'behandlungen/kosmetikbehandlungen',
    loadChildren: () => import('./pages/cosmetic/cosmetic.module').then(m => m.CosmeticModule),
    data: {
      'title': 'Kosmetikbehandlungen mit medizinischen Wirkstoffen bei permanent verschönert Nürnberg',
      'keywords': 'kosmetikstudio Nürnberg, Kosmetik, Wirkstoffkosmetik, medizinisch, Hautprobleme, Gesichtsbehandlungen, Anti age, Couperose, rosacea, falten, akne, von Lupin',
      'description': 'Entdecke die Kraft medizinischer Wirkstoffkosmetik mit von Lupin für gezielte Hautpflege. Erfahre garantierte Hautverbesserung innerhalb von 8 Wochen.',
      'canonical': 'https://www.permanent-verschoenert.de/behandlungen/kosmetikbehandlungen',
      'og:type': 'website',
      'og:title': 'Kosmetikbehandlungen mit medizinischen Wirkstoffen bei permanent verschönert Nürnberg',
      'og:description': 'Entdecke die Kraft medizinischer Wirkstoffkosmetik mit von Lupin für gezielte Hautpflege. Erfahre garantierte Hautverbesserung innerhalb von 8 Wochen.',
      'og:image': 'https://www.permanent-verschoenert.de/assets/images/meta_image.jpg',
      'og:url': 'https://www.permanent-verschoenert.de/behandlungen/kosmetikbehandlungen'
    }
  },
  {
    path: 'behandlungen/aqua-facial',
    loadChildren: () => import('./pages/aqua-facial/aqua-facial.module').then(m => m.AquaFacialModule),
    data: {
      'title': 'Aqua Facial - Tiefenreinigung und Glow für Deine Gesichtshaut',
      'keywords': 'Aqua Facial, Hydrafacial, Hydrofacial, Gesichtsreinigung, Gesichtsbehandlung, Kosmetik, apparativ, Aqua Peel, Peeling',
      'description': 'Lass Deine Haut strahlen und wähle Dein exklusives Aqua Facial Behandlungspaket in meinem Studio',
      'canonical': 'https://www.permanent-verschoenert.de/behandlungen/aqua-facial',
      'og:type': 'website',
      'og:title': 'Aqua Facial - Tiefenreinigung und Glow für Deine Gesichtshaut',
      'og:description': 'Lass Deine Haut strahlen und wähle Dein exklusives Aqua Facial Behandlungspaket in meinem Studio',
      'og:image': 'https://www.permanent-verschoenert.de/assets/images/meta_image.jpg',
      'og:url': 'https://www.permanent-verschoenert.de/behandlungen/aqua-facial'
    }
  },
  {
    path: 'behandlungen/waxing',
    loadChildren: () => import('./pages/waxing/waxing.module').then(m => m.WaxingModule),
    data: {
      'title': 'Waxing & Haarentfernung bei permanent verschönert in Nürnberg',
      'keywords': 'Waxing, Haarentfernung, Haarfrei, Heißwachs, Warmwachs, Wachsstreifen, PINK, Next Generation, Wachs, Wax, Nürnberg',
      'description': 'In meinem Studio biete ich Dir ein schonendes und zugleich gründliches Waxing bereits ab 1mm Haarlänge an! Sag tschüß Rasierer und hallo neues Hautgefühl!',
      'canonical': 'https://www.permanent-verschoenert.de/behandlungen/waxing',
      'og:type': 'website',
      'og:title': 'Waxing & Haarentfernung bei permanent verschönert in Nürnberg',
      'og:description': 'In meinem Studio biete ich Dir ein schonendes und zugleich gründliches Waxing bereits ab 1mm Haarlänge an! Sag tschüß Rasierer und hallo neues Hautgefühl!',
      'og:image': 'https://www.permanent-verschoenert.de/assets/images/meta_image.jpg',
      'og:url': 'https://www.permanent-verschoenert.de/behandlungen/waxing'
    }
  },
  {
    path: 'galerie',
    loadChildren: () => import('./pages/gallery/gallery.module').then(m => m.GalleryModule),
    data: {
      'title': 'Einblicke per Foto oder Video in mein Studio permanent verschönert',
      'keywords': 'Fotos, Bilder, Galerie, Vorher, Nachher',
      'description': 'Du erhältst hier einen Einblick in meine Arbeiten (Lifting, Kosmetik, Waxing & Aqua Facial) und mein Studio in Nürnberg.',
      'canonical': 'https://www.permanent-verschoenert.de/galerie',
      'og:type': 'website',
      'og:title': 'Einblicke per Foto oder Video in mein Studio permanent verschönert',
      'og:description': 'Du erhältst hier einen Einblick in meine Arbeiten (Lifting, Kosmetik, Waxing & Aqua Facial) und mein Studio in Nürnberg.',
      'og:image': 'https://www.permanent-verschoenert.de/assets/images/meta_image.jpg',
      'og:url': 'https://www.permanent-verschoenert.de/galerie'
    }
  },
  {
    path: 'preise',
    loadChildren: () => import('./pages/prices/prices.module').then(m => m.PricesModule),
    data: {
      'title': 'Preise bei Deiner Beautylounge permanent verschönert',
      'keywords': 'Kosten, Preise, Behandlungen, Waxing, Kosmetik, Aqua Facial, Wimpernlifting, Augenbrauenlifting',
      'description': 'Hier findest Du mein komplettes Behandlungsangebot mit Preisen rund um Wimpern, Augenbrauen, Kosmetikbehandlungen und Waxing.',
      'canonical': 'https://www.permanent-verschoenert.de/preise',
      'og:type': 'website',
      'og:title': 'Preise bei Deiner Beautylounge permanent verschönert',
      'og:description': 'Hier findest Du mein komplettes Behandlungsangebot mit Preisen rund um Wimpern, Augenbrauen, Kosmetikbehandlungen und Waxing.',
      'og:image': 'https://www.permanent-verschoenert.de/assets/images/meta_image.jpg',
      'og:url': 'https://www.permanent-verschoenert.de/preise'
    }
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule),
    data: {
      'title': 'Fragen und Antworten zu meinen kosmetischen Behandlungen',
      'keywords': 'Fragen, Antworten, FAQ, Lash Lifting, Brow Lifting, Kosmetik, Waxing, Aqua Facial',
      'description': 'Hier findest Du die meist gestellten Fragen und alle Antworten zu meinen Behandlungen rund um Kosmetik und Waxing.',
      'canonical': 'https://www.permanent-verschoenert.de/faq',
      'og:type': 'website',
      'og:title': 'Fragen und Antworten zu meinen kosmetischen Behandlungen',
      'og:description': 'Hier findest Du die meist gestellten Fragen und alle Antworten zu meinen Behandlungen rund um Kosmetik und Waxing.',
      'og:image': 'https://www.permanent-verschoenert.de/assets/images/meta_image.jpg',
      'og:url': 'https://www.permanent-verschoenert.de/faq'
    }
  },
  {
    path: 'kontakt',
    loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule),
    data: {
      'title': 'Dein Kontakt zur Beautylounge permanent verschönert in Nürnberg',
      'keywords': 'Kontakt, Adresse, Nürnberg, permanent verschönert, Kosmetikstudio',
      'description': 'Meine Beautylounge findest Du in Nürnberg/Gartenstadt. Parkplätze stehen öffentlich vor dem Gebäude zu Verfügung. Mit der Straßenbahn erreichst Du mein Studio mit der Linie 5.',
      'canonical': 'https://www.permanent-verschoenert.de/kontakt',
      'og:type': 'website',
      'og:title': 'Dein Kontakt zur Beautylounge permanent verschönert in Nürnberg',
      'og:description': 'Meine Beautylounge findest Du in Nürnberg/Gartenstadt. Parkplätze stehen öffentlich vor dem Gebäude zu Verfügung. Mit der Straßenbahn erreichst Du mein Studio mit der Linie 5.',
      'og:image': 'https://www.permanent-verschoenert.de/assets/images/meta_image.jpg',
      'og:url': 'https://www.permanent-verschoenert.de/kontakt'
    }
  },
  {
    path: 'onlinebuchung',
    loadChildren: () => import('./pages/booking/booking.module').then(m => m.BookingModule),
    data: {
      'title': 'Termin Onlinebuchung bei permanent verschönert in Nürnberg',
      'keywords': 'Online, Buchung, buchen, Termin, Vereinbarung, vereinbaren, Kosmetik',
      'description': 'Buche Deinen Termin in meiner Beautylounge in Nürnberg ganz einfach rund um die Uhr über meine Webseite.',
      'canonical': 'https://www.permanent-verschoenert.de/onlinebuchung',
      'og:type': 'website',
      'og:title': 'Termin Onlinebuchung bei permanent verschönert in Nürnberg',
      'og:description': 'Buche Deinen Termin in meiner Beautylounge in Nürnberg ganz einfach rund um die Uhr über meine Webseite.',
      'og:image': 'https://www.permanent-verschoenert.de/assets/images/meta_image.jpg',
      'og:url': 'https://www.permanent-verschoenert.de/onlinebuchung'
    }
  },
  {
    path: 'impressum',
    loadChildren: () => import('./pages/imprint/imprint.module').then(m => m.ImprintModule),
    data: {
      'title': 'Das Impressum deiner Beautylounge permanent verschönert',
      'keywords': 'Impressum',
      'description': 'permanent verschönert Inh. Michaela Büchler Pirmasenser Str. 136 90469 Nürnberg Mobil: +49 1512 8358406 Email: info@permanent-verschoenert.de',
      'canonical': 'https://www.permanent-verschoenert.de/impressum',
      'og:type': 'website',
      'og:title': 'Impressum permanent verschönert',
      'og:description': 'permanent verschönert Inh. Michaela Büchler Pirmasenser Str. 136 90469 Nürnberg Mobil: +49 1512 8358406 Email: info@permanent-verschoenert.de',
      'og:image': 'https://www.permanent-verschoenert.de/assets/images/meta_image.jpg',
      'og:url': 'https://www.permanent-verschoenert.de/impressum'
    }
  },
  {
    path: 'datenschutz',
    loadChildren: () => import('./pages/privacy/privacy.module').then(m => m.PrivacyModule),
    data: {
      'title': 'Datenschutz ist mir ein anliegen bei permanent verschönert',
      'keywords': 'Datenschutz, Erklärung, Datenschutzerklärung',
      'description': 'Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen.',
      'canonical': 'https://www.permanent-verschoenert.de/datenschutz',
      'og:type': 'website',
      'og:title': 'Datenschutz ist mir ein anliegen bei permanent verschönert',
      'og:description': 'Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen.',
      'og:image': 'https://www.permanent-verschoenert.de/assets/images/meta_image.jpg',
      'og:url': 'https://www.permanent-verschoenert.de/datenschutz'
    }
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
