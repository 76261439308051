<mat-toolbar>
  <button aria-label="Menü" mat-icon-button class="menu-button" (click)="drawerService.toggle()">
    <mat-icon>menu</mat-icon>
  </button>

  <picture>
    <source type="image/avif" srcset="/assets/images/logo_white_180px.avif">
    <img width="180" height="180" class="toolbar-logo" src="/assets/images/logo_white_180px.png" (click)="linkService.route('/')" title="Logo" alt="permanent verschönert Logo">
  </picture>
  <span class="toolbar-text" (click)="linkService.route('/')">permanent verschönert</span>

  <div class="toolbar-info">
    <span class="toolbar-spacer"></span>
    <div class="toolbar-contact" (click)="linkService.phone()">
      <mat-icon>call</mat-icon>
      <div class="info">
        <span class="title">Telefon/WhatsApp</span>
        <span class="text">+49 1512 8358406</span>
      </div>
    </div>
    <mat-divider [vertical]="true"></mat-divider>
    <div class="toolbar-contact" (click)="linkService.email()">
      <mat-icon>mail_outline</mat-icon>
      <div class="info">
        <span class="title">Email</span>
        <span class="text">info@permanent-verschoenert.de</span>
      </div>
    </div>
    <mat-divider [vertical]="true"></mat-divider>
    <div class="toolbar-contact" (click)="linkService.route('/kontakt')">
      <mat-icon>schedule</mat-icon>
      <div class="info">
        <span class="title">Öffnungszeiten</span>
        <span class="text">Termine nach Vereinbarung</span>
      </div>
    </div>
  </div>
</mat-toolbar>
