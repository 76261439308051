import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DOCUMENT, Location } from '@angular/common';
import { MatDrawer } from '@angular/material/sidenav';

import { DrawerService } from './services/drawer.service';
import { TrackingService } from './services/tracking.service';
import { ScrollService } from './services/scroll.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild(MatDrawer) drawer!: MatDrawer;

  public snowflake: boolean = false;
  public snowflakes: Array<number> = [];

  constructor(
    @Inject(DOCUMENT) private domService: any,
    private location: Location,
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public drawerService: DrawerService,
    private trackingService: TrackingService,
    private scrollService: ScrollService
  ) {}

  ngOnInit(): void {
    this.setDrawer();

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.setMetaTags();
      this.trackingService.trackRouteChange();
      this.scrollService.toElement('top');
    });

    if (this.isWinter()) this.addSnowFlakes();
  }

  addSnowFlakes(): void {
    this.snowflakes = Array(150).fill(0).map((x,i)=>i);

    if (/bot|google|baidu|bing|msn|teoma|slurp|yandex/i.test(navigator.userAgent)) {
      localStorage.setItem('snowflake', 'false');
      this.snowflake = false;
    } else {
      const snowflake = localStorage.getItem('snowflake');
      this.snowflake = snowflake === 'false' ? false : true;
    }
  }

  isWinter(): boolean {
    const date = new Date();

    if (date.getMonth() >= 10 || date.getMonth() <= 1) {
      return true;
    }

    return false;
  }

  setDrawer(): void {
    if (this.drawer) {
      this.drawerService.setDrawer(this.drawer);
    } else {
      setTimeout(() => {
        this.setDrawer();
      }, 300);
    }
  }

  setMetaTags(): void {
    this.getChild(this.activatedRoute).data.subscribe((data: any) => {
      if (data.title) {
        this.titleService.setTitle(data.title);
      }

      for (let key in data) {
        if (key == 'canonical') {
          this.updateCanonicalUrl(data.canonical);
        } else if (data[key] && !key.startsWith('og:')) {
          this.metaService.updateTag({ name: key, content: data[key] });
        } else if (data[key] && key.startsWith('og:')) {
          this.metaService.updateTag({ property: key, content: data[key] });
        } else {
          this.metaService.removeTag(`name=${key}`)
          this.metaService.removeTag(`property=${key}`)
        }
      }
    })
  }

  updateCanonicalUrl(url: string): void {
    const head: HTMLElement = this.domService.getElementsByTagName('head')[0];
    let element: HTMLLinkElement = this.domService.querySelector(`link[rel='canonical']`) || null;

    if (element == null) {
      element = this.domService.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }

    element.setAttribute('rel', 'canonical')
    element.setAttribute('href', url)
  }

  updatePreloadHeaderImage(url: string): void {
    const head: HTMLElement = this.domService.getElementsByTagName('head')[0];
    let element: HTMLLinkElement = this.domService.querySelector(`link[rel='preload']`) || null;

    if (element == null) {
      element = this.domService.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }

    element.setAttribute('rel', 'preload')
    element.setAttribute('as', 'image')
    element.setAttribute('href', url)
  }

  getChild(activatedRoute: ActivatedRoute): any {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  isBooking(): boolean {
    if (this.location.path() == '/onlinebuchung') {
      return true;
    } else {
      return false;
    }
  }

  toggleSnow(): void {
    if (this.snowflake) {
      localStorage.setItem('snowflake', 'false')
      this.snowflake = false;
    } else {
      localStorage.setItem('snowflake', 'true')
      this.snowflake = true;
    }
  }
}
