import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

import { DrawerService } from './drawer.service';

@Injectable({
  providedIn: 'root'
})
export class LinkService {
  private lastTrackedEvent: string = '';

  constructor(
    @Inject(DOCUMENT) private _doc: Document,
    private router: Router,
    private drawerService: DrawerService
  ) { }

  private getWindow(): Window | null {
    return this._doc.defaultView;
  }

  private openLink(link: string, target: string = '_self'): void {
    const browserWindow: Window | null = this.getWindow();

    if (browserWindow) {
      browserWindow.open(link, target);
      this.drawerService.close();
    }
  }

  route(link: string, fragment?: string): void {
    this.router.navigate([link], { fragment: fragment });
    this.drawerService.close();
  }

  baseRoute(baseRoute: string): boolean {
    if (this.router.url.startsWith(`/${baseRoute}`)) {
      return true
    } else {
      return false;
    }
  }

  phone(): void {
    (<any>window)._paq.push(['trackEvent', 'Link', 'Telefon']);
    this.openLink('tel:004915128358406');
  }

  email(): void {
    (<any>window)._paq.push(['trackEvent', 'Link', 'Email']);
    this.openLink('mailto:info@permanent-verschoenert.de');
  }

  maps(): void {
    (<any>window)._paq.push(['trackEvent', 'Link', 'Maps']);
    this.openLink('https://goo.gl/maps/9bvvrAm2vcrNaCa4A', '_blank');
  }
}
