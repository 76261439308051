<div class="sub-toolbar">
  <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="drawerService.close()">Startseite</a>
  <a routerLink="/ueber-mich" routerLinkActive="active" (click)="drawerService.close()">Über mich</a>
  <a (click)="trackingService.trackEvent('Link', 'von Lupin')" href="https://von-lupin.com/startseite?affiliateparlorlink=184cb48a11bd10712686ebd5611812af" target="_blank" (click)="drawerService.close()">Onlineshop</a>
  <span [ngClass]="isMenuActive('behandlungen')" [matMenuTriggerFor]="menuTreatments">Behandlungen</span>
  <mat-menu #menuTreatments="matMenu">
    <a routerLink="/behandlungen/wimpernlifting" mat-menu-item (click)="drawerService.close()">Wimpernlifting</a>
    <a routerLink="/behandlungen/augenbrauenlifting" mat-menu-item (click)="drawerService.close()">Augenbrauenlifting</a>
    <a routerLink="/behandlungen/wow-brows" mat-menu-item (click)="drawerService.close()">WowBrows</a>
    <a routerLink="/behandlungen/kosmetikbehandlungen" mat-menu-item (click)="drawerService.close()">Kosmetikbehandlungen</a>
    <a routerLink="/behandlungen/aqua-facial" mat-menu-item (click)="drawerService.close()">Aqua Facial</a>
    <a routerLink="/behandlungen/waxing" mat-menu-item (click)="drawerService.close()">Waxing</a>
  </mat-menu>
  <a routerLink="/galerie" routerLinkActive="active" (click)="drawerService.close()">Galerie</a>
  <a routerLink="/preise" routerLinkActive="active" (click)="drawerService.close()">Preise</a>
  <a routerLink="/faq" routerLinkActive="active" (click)="drawerService.close()">FAQ</a>
  <a routerLink="/kontakt" routerLinkActive="active" (click)="drawerService.close()">Kontakt</a>
  <a routerLink="/onlinebuchung" routerLinkActive="active" (click)="drawerService.close()">Onlinebuchung</a>
</div>
