<button aria-label="Schließen" mat-icon-button class="close-button" (click)="drawerService.toggle()">
  <mat-icon >clear</mat-icon>
</button>

<mat-list>
  <mat-list-item>
    <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="drawerService.close()">Startseite</a>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item>
    <a routerLink="/ueber-mich" routerLinkActive="active" (click)="drawerService.close()">Über mich</a>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item>
    <a (click)="trackingService.trackEvent('Link', 'von Lupin')" href="https://von-lupin.com/startseite?affiliateparlorlink=184cb48a11bd10712686ebd5611812af" target="_blank" (click)="drawerService.close()">Onlineshop</a>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item>Behandlungen</mat-list-item>
  <mat-list-item class="sub-item">
    <a routerLink="/behandlungen/wimpernlifting" routerLinkActive="active" (click)="drawerService.close()">Wimpernlifting</a>
  </mat-list-item>
  <mat-list-item class="sub-item">
    <a routerLink="/behandlungen/augenbrauenlifting" routerLinkActive="active" (click)="drawerService.close()">Augenbrauenlifting</a>
  </mat-list-item>
  <mat-list-item class="sub-item">
    <a routerLink="/behandlungen/wow-brows" routerLinkActive="active" (click)="drawerService.close()">WowBrows</a>
  </mat-list-item>
  <mat-list-item class="sub-item">
    <a routerLink="/behandlungen/kosmetikbehandlungen" routerLinkActive="active" (click)="drawerService.close()">Kosmetikbehandlungen</a>
  </mat-list-item>
  <mat-list-item class="sub-item">
    <a routerLink="/behandlungen/aqua-facial" routerLinkActive="active" (click)="drawerService.close()">Aqua Facial</a>
  </mat-list-item>
  <mat-list-item class="sub-item">
    <a routerLink="/behandlungen/waxing" routerLinkActive="active" (click)="drawerService.close()">Waxing</a>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item>
    <a routerLink="/preise" routerLinkActive="active" (click)="drawerService.close()">Preise</a>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item>
    <a routerLink="/galerie" routerLinkActive="active" (click)="drawerService.close()">Galerie</a>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item>
    <a routerLink="/faq" routerLinkActive="active" (click)="drawerService.close()">FAQ</a>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item>
    <a routerLink="/kontakt" routerLinkActive="active" (click)="drawerService.close()">Kontakt</a>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item>
    <a routerLink="/onlinebuchung" routerLinkActive="active" (click)="drawerService.close()">Onlinebuchung</a>
  </mat-list-item>
  <mat-divider></mat-divider>
</mat-list>

<div class="toolbar-contact" (click)="linkService.phone()">
  <mat-icon>call</mat-icon>
  <div class="info">
    <span class="title">Telefon/WhatsApp</span>
    <span class="text">+49 1512 8358406</span>
  </div>
</div>
<mat-divider></mat-divider>
<div class="toolbar-contact" (click)="linkService.email()">
  <mat-icon>mail_outline</mat-icon>
  <div class="info">
    <span class="title">Email</span>
    <span class="text">info@permanent-verschoenert.de</span>
  </div>
</div>
<mat-divider></mat-divider>
<div class="toolbar-contact" (click)="linkService.route('/kontakt')">
  <mat-icon>schedule</mat-icon>
  <div class="info">
    <span class="title">Öffnungszeiten</span>
    <span class="text">Termine nach Vereinbarung</span>
  </div>
</div>
